import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { Content } from './components/Content';
import { Content2 } from './components/Content2';
import { Content3 } from './components/Content3';
import { Content4 } from './components/Content4';



function App() {

  return (
        <Routes>
          <Route path='/' element={<Content />}></Route>
          <Route path='/portfolio' element={<Content2 />}></Route>
          <Route path='/about-me' element={<Content3 />}></Route>
          <Route path='/contact' element={<Content4 />}></Route>
        </Routes>
  )
};

export default App;