import React from 'react';
// import { CgLaptop }from 'react-icons/cg';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';


export const NavBar = () => {

  return (
    <div className='relative'>
      <div className='p-6 lg:pl-16 pb-6 flex justify-between items-center w-screen fixed h-[60px] z-50'>
          <motion.div className='flex flex-row space-x-4 items-center justify-center'
            initial={{ y: -20, opacity: 0}}
            animate={{ y: 0, opacity: 1}}
            transition={{duration: 1}}
            >
            {/* <CgLaptop className='h-10 lg:h-16 w-10 lg:w-16 text-center' color='#08fdd8'/> */}
            <Link to='/'><h1 className="lg:font-bold text-xl font-light text-[#08fdd8] py-1 px-4 tracking-widest bg-[#000000]/95 opacity-100 items-center">
            OLORUNTEGBE'S PORTFOLIO 
            </h1></Link>
          </motion.div>
      </div>
    </div>
  )
}








