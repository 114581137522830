import React from 'react'
import { CardAnime } from './animations/CardAnime';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { NavBar } from './NavBar';
import { NavBarrrr } from './NavBarrrr';
import { Footer } from './Footer';


export const Content3 = () => {

    const {ref, inView} = useInView();
    const animation = useAnimation();

    const AnimatedLetters = ({title}) => (
        <motion.span className='flex flex-row' variants={wrapper} initial='initial' animate='animate'>
          {[...title].map((letter) => (
            <motion.span className='flex lg:text-5xl text-2xl font-bold uppercase text-[#08fdd8] tracking-widest' variants={letterAnimation}>{letter}</motion.span>
          ))}
        </motion.span>
      );
    
      useEffect(() => {
        console.log('use effect hook, inView = ', inView);
        if(inView){
          animation.start({
            rotateX: 360,
            transition: {
             duration: 1.5, delay: 0.5
            }
          })
        }
        if(!inView){
          animation.start({rotateX: 180})
        }
    
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [inView]);
    

      const wrapper = {
        animate: { 
          transition: {
            delayChildren: 0.4,
            staggerChildren: 0.2,
        },},
      };
    
      const letterAnimation = {
        initial: {
          opacity: 0,
        },
        animate: {
          opacity: 1,
          // rotate: 360,
          transition: {
            ease:[0.6, 0.01, -0.05, 0.95],
            // ease: 'circInOut',
            type: 'spring',
            duration: 1.5,
            bounce: 0.4,
          }
        }
      }

  return (
    <div className="relative bg-[#000000]/95 w-screen">
      <NavBar />
      <NavBarrrr />
      <div className="overflow-hidden px-8 lg:py-12 py-16 relative">
        <div className="flex flex-col items-center lg:px-20" ref={ref}>
          <div className="flex flex-col gap-2">
            <motion.header
              className="flex lg:justify-center lg:items-cente space-x-4"
              variants={wrapper}
            >
              <AnimatedLetters title={"About"} />
              <AnimatedLetters title={"me"} />
            </motion.header>
            <p className="relative text-lg lg:tracking-widest text-[#d4d2d2] lg:mt-4 mt-2 w-[95%]">
              Passionate and dedicated full stack web developer with a strong
              desire to continuously learn and enhance my skills. I am a
              dependable, quick learner who thrives in team environments and
              values effective communication and organization.
            </p>
            <p className="relative text-lg lg:tracking-widest text-[#d4d2d2] w-[95%]">
              I am always ready to take on new challenges to achieve goals and
              gain the knowledge and expertise needed to grow and improve every
              day. My commitment to both frontend and backend development drives
              my enthusiasm for creating seamless, user-friendly web
              applications.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1gjRmlvcqn68BD908L7EEa6BqhFIqHg-3/view?usp=drivesdk"
              className="text-[#08fdd8] text-xl px-6 py-3 justify-center text-center bg-[#1d1d1d] border-solid rounded-md border-4 border-[#08fdd8] hover:bg-[#08fdd8] hover:text-[#1d1d1d] hover:transition hover:duration-200 w-fit mt-5"
            >
              See Resume...
            </a>
          </div>
          <div className="flex  mt-8" ref={ref}>
            <motion.div animate={animation}>
              <CardAnime />
            </motion.div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
