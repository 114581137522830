import React, { useState } from 'react';
import { FaInstagram, FaTwitter } from 'react-icons/fa';
import { BsGithub, BsWhatsapp, BsHouse, BsLinkedin, BsBriefcase, BsPhoneFill } from 'react-icons/bs';
import { AiOutlineClose, AiOutlineMenu, AiOutlineUser }from 'react-icons/ai';
import { motion } from 'framer-motion';
import { NavLink, Link } from 'react-router-dom';


export const NavBarrrr = () => {
    const[toggleMenu, setToggleMenu] = useState(false)

  return (
    <div className="flex px-8 w-screen items-center justify-end relative">
      <div className="fixed top-0  h-screen items-center flex z-50">
        <motion.div
          className="hidden lg:flex flex-col  gap-8"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {[
            { to: "/", icon: <BsHouse className="h-8 w-8" />, text: "Home" },
            {
              to: "/about-me",
              icon: <AiOutlineUser className="h-8 w-8" />,
              text: "About",
            },
            {
              to: "/portfolio",
              icon: <BsBriefcase className="h-8 w-8" />,
              text: "Portfolio",
            },
            {
              to: "/contact",
              icon: <BsPhoneFill className="h-8 w-8" />,
              text: "Contact",
            },
          ].map(({ to, icon, text }) => (
            <NavLink
              key={to}
              to={to}
              className={
                ({ isActive }) =>
                  `group relative flex items-center p-2 w-12 h-12 rounded-full text-white cursor-pointer transition-all duration-300 ease-out overflow-hidden ${
                    isActive ? "bg-[#ffffff]/10" : "bg-[#08fdd8]/80"
                  } hover:w-36` // Use a fixed width on hover
              }
            >
              <div className="flex justify-center items-center w-12">
                {icon}
              </div>
              <span className="absolute left-[3.5rem] text-lg font-medium whitespace-nowrap transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100">
                {text}
              </span>
            </NavLink>
          ))}
        </motion.div>

        <div>
          <AiOutlineMenu
            className="ml-[1rem] lg:hidden"
            color="#08fdd8"
            size={26}
            onClick={() => {
              setToggleMenu(true);
            }}
          />
        </div>
      </div>

      {toggleMenu && (
        <motion.div
          className="flex items-center flex-col text-start z-20 justify-start bg-[#1d1d1d] p-8 mt-2 top-12 right-0 fixed w-screen bottom-0 lg:hidden h-fit"
          initial={{ x: "100vw" }}
          animate={{ x: 0 }}
          transition={{ type: "spring", duration: 2, bounce: 0.3 }}
        >
          <AiOutlineClose
            color="#08fdd8"
            size={26}
            onClick={() => {
              setToggleMenu(false);
            }}
          />
          <hr className="h-[2px] bg-[#08fdd8] w-full my-2"></hr>
          <Link
            to="/about-me"
            onClick={() => {
              setToggleMenu(false);
            }}
          >
            <p className="p-2 text-gray-400">About</p>
          </Link>
          <hr className="h-[2px] bg-[#08fdd8] w-full my-2"></hr>
          <Link
            to="/portfolio"
            onClick={() => {
              setToggleMenu(false);
            }}
          >
            <p className="p-2 text-gray-400">Projects</p>
          </Link>
          <hr className="h-[2px] bg-[#08fdd8] w-full my-2"></hr>
          <Link
            to="/contact"
            onClick={() => {
              setToggleMenu(false);
            }}
          >
            <p className="p-2 text-gray-400">Contact</p>
          </Link>
          <hr className="h-[2px] bg-[#08fdd8] w-full my-2"></hr>
          <div className="flex flex-row space-x-4 mt-2">
            <a
              href="https://github.com/Pelumi-oloruntegbe"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400"
            >
              <BsGithub />
            </a>
            <a
              href="https://www.linkedin.com/in/pelumi-oloruntegbe-301261320/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400"
            >
              <BsLinkedin />
            </a>
            <a
              href="https://wa.me/2348148627143"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400"
            >
              <BsWhatsapp />
            </a>
            <a
              href="https://twitter.com/_pelumiofficial"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400"
            >
              <FaTwitter />
            </a>
            <a
              href="https://instagram.com/__pelzgram"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400"
            >
              <FaInstagram />
            </a>
          </div>
        </motion.div>
      )}
      {/* <a href='https://github.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer ease-out text-gray-400'><BsGithub /></a>
          <a href='https://www.linkedin.com/in/tofunmi-tijani-1a796024a/' target='_blank' rel='noreferrer' className='m-8 text-gray-400'><BsLinkedin /></a>
          <a href='https://wa.me/2348102684993' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-gray-400'><BsWhatsapp /></a>
          <a href='https://twitter.com/tijaniix' target='_blank' rel='noreferrer' className='text-2xl ml-5 leading-6 cursor-pointer ease-out text-gray-400'><FaTwitter /></a> */}
    </div>
  );
}








