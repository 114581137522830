import React from "react";
// import {FcBinoculars} from 'react-icons/fc';
// import {GoCode} from 'react-icons/go';

export const Cards = ({ title, stack, desc, live, github, id, src }) => {
  return (
    <div className="flex">
      <div
        className="rounded-md flex flex-col bg-[#ffffff]/10 p-5 relative lg:w-[21rem] lg:h-[18rem] h-fit gap-6 hover:bg-transparent hover:border hover:border-[#08fdd8] hover:transition hover:duration-500"
        key={id}
      >
        <img src={src} alt="" className="w-full h-32 rounded-md" />
        <span className="flex flex-col gap-2">
          <span className="text-xl text-[#d4d2d2] tracking-wider">{title}</span>
          <span className="text-sm text-[#d4d2d2]/80">{desc}</span>
          {/* <span className='p-3 text-lg border border-[#08fdd8] w-fit rounded-md'><span>View project</span></span> */}
        </span>
      </div>
      {/* <div className='absolute opacity-0 top-0 left-0 right-0 bottom-0 flex justify-center flex-col text-center bg-black hover:opacity-95 hover:transition hover:duration-500'>
          <h2 className='text-2xl p-2 border-b-2 border-b-solid border-[#08fdd8]  text-[#08fdd8]/70'>{title}</h2>
          <h4 className='p-2 text-[#08fdd8]/70'>{stack}</h4>
          <p className='p-2 text-[#08fdd8]/70 text-sm'>{desc}</p>
        </div>     */}
    </div>
  );
};
